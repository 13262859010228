import React, { useState, useContext, useRef } from "react";
import {layoutContext} from '../context';
import {userContext} from '../context';

import { faPhone, faEnvelope,faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buscarEnd,setNewDataProfile } from "../api";
import { useHistory } from "react-router-dom";
import { addWarning, removeWarning, convertToDate, destroyToken } from "../utils";
import PopupPerfil from "./popupsPerfil";


export default function Perfil({match,mobile,logo,mdi_idMobile}) {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const iosAgent = userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i )?true:false;
  const androidAgent = userAgent.match( /Android/i )
  const history = useHistory();

  const [
    {layoutData},
    {_},
    {text}
  ] = useContext(layoutContext)
  const [
    {userData},
    {professions},
    {countries}
  ] = useContext(userContext)
  
  const mdi_id = match.params.mdi_id?match.params.mdi_id:mdi_idMobile
  const view = match.params.view
  const joyceLayout = mdi_id==="229"&&mobile?"joyceLayout":""
 
  const listaGruposComprar = userData?.grupos_pagos;
  const listaGrupos = userData?.grupos
  const tipoForm = mdi_id?layoutData.tipo_form_cadastro:1;
  const listContacts = userData.contatos_neo;

  const [statusPopup, setStatusPopup] = useState("disable");
  const [popupMessage, setPopupMessage] = useState("");
  const [popupBtn, setPopupBtn] = useState(true);

  const [email, setEmail] = useState(userData.email);
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState(userData.nome);
  const [sobrenome, setSobrenome] = useState(userData.sobrenome);
  const [nascimento, setNascimento] = useState(userData.nascimento);
  const [sexo, setSexo] = useState(userData.sexo);
  const [pais, setPais] = useState(countries.find(pais => pais.codigo.toString() === userData.codigo_pais)?.valor);
  const [paisCodigo, setPaisCodigo] = useState(countries.find(pais => pais.codigo.toString() === userData.codigo_pais)?.codigo);
  const [profissao, setProfissao] = useState(userData.profissao);
  const [profissaoCodigo, setProfissaoCodigo] = useState("");
  const [cep, setCep] = useState(userData.cep);
  const [estado, setEstado] = useState(userData.estado);
  const [cidade, setCidade] = useState(userData.cidade);
  const [endereco, setEndereco] = useState(userData.endereco);
  const [numero, setNumero] = useState(userData.numero);
  const [bairro, setBairro] = useState(userData.bairro);
  const [complemento, setComplemento] = useState(userData.complemento);
  const [celularDdi, setCelularDdi] = useState(userData.celular_ddi);
  const [celular, setCelular] = useState(userData.celular_numero);
  const [telefoneDdi, setTelefoneDdi] = useState(userData.telefone_ddi);
  const [telefone, setTelefone] = useState(userData.telefone_numero);

  const inputSenha = useRef()
  const inputConfirmaSenha = useRef()
  const inputNascimento = useRef()
  const inputProfession = useRef()
  const inputPais = useRef()

  const showHeader = () => {
    if (!mobile) {
      return (
        <>
          <FontAwesomeIcon
            className="Perfil_headerIcon"
            icon={faUserAlt}
          />
          <p className="popup__title">{text.atualizarPerfil}</p>
        </>
      );
    }else if(layoutData.logo&&logo==="true"){
      return <img className="form__perfilLogo" src={layoutData.logo} alt={`logo ${layoutData.nome}`}></img>
    }
  };
  const confirmarSenha = () => {
    if (senha === inputConfirmaSenha.current.value) {
      removeWarning(inputConfirmaSenha.current)
      return;
    }
    addWarning(inputConfirmaSenha.current);
  };
  const handleNascimento = (value) => {
     
      if (!isNaN(value.slice(-1))) {
        if (value.length === 2 || value.length === 5) {
          setNascimento(value + "/");
        } else {
          setNascimento(value);
        }
      } else if (value.slice(-1) === "/") {
        setNascimento(value.slice(0, value.length - 1));
      }
  };
  const confirmarNascimento = (value) => {
      const today = new Date();
      const time = convertToDate(value)
      if (
        (!isNaN(time) &&
          (today - time) / 31104000000 >= 8 &&
          (today - time) / 31104000000 <= 110) ||
        nascimento === ""
      ) {
        removeWarning(inputNascimento.current);
      } else {
        addWarning(inputNascimento.current);
      }
  };
  const showProfession = () => {
      if (androidAgent) {
        return (
          <>
            <select
              ref={inputProfession}
              id="inputProfissao"
              onChange={(e) => confirmarProfissao(e.target.value)}
              className={"form__input "+joyceLayout}
            >
              <option defaultValue value={profissao}>
                {profissao}
              </option>
              {professions?.map((profissao) => (
                <option key={profissao.codigo} value={profissao.valor}>
                  {profissao.valor}
                </option>
              ))}
            </select>
          </>
        );
      }
    return (
          <>
            <input
              ref={inputProfession}
              placeholder={text.profissao}
              value={profissao}
              id="inputProfissao"
              onChange={(e) => setProfissao(e.target.value)}
              onBlur={(e) => confirmarProfissao(e.target.value)}
              className={"form__input "+joyceLayout}
              list="profissao"
              name="profissao"
            />
    
            <datalist id="profissao">
              {professions?.map((profissao) => {
                return <option key={profissao.codigo} value={profissao.valor}>
                  {profissao.valor}
                </option>}
              )}
            </datalist>
          </>
        );
  };
  const confirmarProfissao = (value) => {
  
      const lista = professions.map((profissao) => profissao.valor);

      if (lista.includes(value)) {
        setProfissaoCodigo(
          professions.find((profissao) => profissao.valor === value)
            .codigo
        );
        removeWarning(inputProfession.current);
      } else if (value === "") {
        removeWarning(inputProfession.current);
      } else {
        addWarning(inputProfession.current);
      }
  }
  const showSexo = () => {
    if (sexo === "Feminino") {
      return (
        <>
          <option defaultValue value="Feminino">{text.feminino}</option>
          <option value="Masculino">{text.masculino}</option>
          <option value="Outro">{text.outro}</option>
        </>
      );
    } else if (sexo === "Masculino") {
      return (
        <>
          <option defaultValue value="Masculino">{text.masculino}</option>
          <option value="Feminino">{text.feminino}</option>
          <option value="Outro">{text.outro}</option>
        </>
      );
    } else if (sexo === "Outro") {
      return (
        <>
          <option defaultValue value="Outro">{text.outro}</option>
          <option value="Feminino">{text.feminino}</option>
          <option value="Masculino">{text.masculino}</option>
            
        </>
      );
    } else if (sexo === "") {
      return (
        <>
          <option>{text.genero}</option>
          <option value="Feminino">{text.feminino}</option>
          <option value="Masculino">{text.masculino}</option>
          <option defaultValue value="Outro">
            {text.outro}
          </option>
        </>
      );
 
    }
  };
  const showPais = () => {
    if (androidAgent) {
      return (
        <>
          <select
            ref={inputPais}
            id="inputPais"
            onChange={(e) => setPais(e.target.value)}
            onBlur={(e) => confirmarPais(e.target.value)}
            className={"form__input "+joyceLayout}
          >
            <option defaultValue value={pais}>
              {pais}
            </option>
            {countries?.map((pais) => (
              <option key={pais.codigo} value={pais.valor}>
                {pais.valor}
              </option>
            ))}
          </select>
        </>
      );
    }

    return (
      <>
        <input
          ref={inputPais}
          placeholder={text.pais}
          value={pais}
          id="inputPais"
          onChange={(e) => setPais(e.target.value)}
          onBlur={(e) => confirmarPais(e.target.value)}
          className={"form__input "+joyceLayout}
          list="paises"
          name="paises"
        />

        <datalist id="paises">
          {countries?.map((pais) => (
            <option key={pais.codigo} value={pais.valor}>
              {pais.valor}
            </option>
          ))}
        </datalist>
      </>
    );
  };
  const confirmarPais = (value) => {
      const lista = countries.map((pais) => pais.valor);
      if (lista.includes(value)) {
        setPaisCodigo(
          countries.find((pais) => pais.valor === value).codigo
        );
        updateDdi(countries.find((pais) => pais.valor === value).ddi)
        removeWarning(inputPais.current);
        
      } else if (value === "") {
        removeWarning(inputPais.current);
      } else {
        addWarning(inputPais.current);
      }
      
  }
  const updateDdi = (ddi) => {
    if(celularDdi===""){
      setCelularDdi("+"+ddi)
    }
    if(telefoneDdi===""){
      setTelefoneDdi("+"+ddi)
    }
  }
  const handleCep = (value) => {
      if (pais === "Brasil") {
        if (!isNaN(value.slice(-1))) {
          if (value.length === 2) {
            setCep(value + ".");
          } else if (value.length === 6) {
            setCep(value + "-");
          } else if (value.length <= 10) {
            setCep(value);
          }
        } else if (
          value.slice(-1) === "-" ||
          value.slice(-1) === "."
        ) {
          setCep(value.slice(0, value.length - 1));
        }
      } else {
        if (!isNaN(value.slice(-1))) {
          setCep(value);
        }
      }
  };
  const confirmarCep = async (value) => {
    if(value){
      const dados = await buscarEnd(paisCodigo, value);
      if (dados.retorno === true) {
        setBairro(dados.dados.bairro);
        setCidade(dados.dados.cidade);
        setEstado(dados.dados.estado);
        setEndereco(dados.dados.logradouro);
      }
    }
  };
  const handleDdi = (value) => {
    if (!isNaN(value.slice(-1))) {
      if (value.length === 1) {
        return ("+" + value);
      } else if (value.length <= 15) {
        return (value);
      }
    } else if (value.slice(-1) === "+") {
      return (value.slice(0, value.length - 1));
    }
  };
  const handleCelular = (value) => {
      if (pais === "Brasil") {
        if (!isNaN(value.slice(-1))) {
          if (value.length === 1) {
            setCelular("(" + value);
          } else if (value.length === 3) {
            setCelular(value + ") ");
          } else if (value.length <= 15) {
            setCelular(value);
          }
        } else if (
          value.slice(-1) === "(" ||
          value.slice(-1) === ")" ||
          value.slice(-1) === "-"
        ) {
          setCelular(value.slice(0, value.length - 1));
        }
      } else {
        if (!isNaN(value.slice(-1))) {
          setCelular(value);
        }
      }
  };
  const handleTelefone = (value) => {
      if (pais === "Brasil") {
        if (!isNaN(value.slice(-1))) {
          if (value.length === 1) {
            setTelefone("(" + value);
          } else if (value.length === 3) {
            setTelefone(value + ") ");
          } else if (value.length <= 15) {
            setTelefone(value);
          }
        } else if (
          value.slice(-1) === "(" ||
          value.slice(-1) === ")" ||
          value.slice(-1) === "-"
        ) {
          setTelefone(value.slice(0, value.length - 1));
        }
      } else {
        if (!isNaN(value.slice(-1))) {
          setTelefone(value);
        }
      }
  };
  const showContacts = () => {
      if(listContacts){
        const contsctsTypes = Object.keys(listContacts)
        
        const arrayContacts = contsctsTypes.map(item => {

          if(listContacts[item]) return {type:item,url:listContacts[item]}
          return undefined
        }).filter(item=>item!==undefined)
  
      return (
        <div className="perfil_container">
        <h3>{text.contatos}</h3>
        {arrayContacts.map(item=>{
          if(item.type==="telefone"){return (
            <p key={item.type} className="perfil_contacts">
              <a target={iosAgent?"":"_blank"} href={`tel:${item.url}`}>
                <FontAwesomeIcon className="contact_icon" icon={faPhone} />
                {item.url}
              </a>
            </p>
          )}else if(item.type==="email"){return (
            <p key={item.type} className="perfil_contacts">
              <a target={iosAgent?"":"_blank"} href={`mailto:${item.url}`}>
                <FontAwesomeIcon className="contact_icon" icon={faEnvelope} />
                {item.url}
              </a>
            </p>
          )}else if(item.type==="youtube"){return (
            <p key={item.type} className="perfil_contacts">
              <a target={iosAgent?"":"_blank"} href={item.url}>
                <FontAwesomeIcon className="contact_icon" icon={faYoutube} />
                /{getLastUrlContent(item.url)}
              </a>
            </p>
          )}else if(item.type==="facebook"){return (
            <p key={item.type} className="perfil_contacts">
              <a target={iosAgent?"":"_blank"}  href={item.url}>
                <FontAwesomeIcon className="contact_icon" icon={faFacebook} />
                /{getLastUrlContent(item.url)}
              </a>
            </p>
          )}else if(item.type==="instagram"){return (
            <p key={item.type} className="perfil_contacts">
              <a target={iosAgent?"":"_blank"} href={item.url}>
                <FontAwesomeIcon className="contact_icon" icon={faInstagram} />
                /{getLastUrlContent(item.url)}
              </a>
            </p>
          )}else if(item.type==="linkedin"){return (
            <p key={item.type} className="perfil_contacts">
              <a target={iosAgent?"":"_blank"} href={item.url}>
                <FontAwesomeIcon className="contact_icon" icon={faLinkedin} />
                /{getLastUrlContent(item.url)}
              </a>
            </p>
          )}else if(item.type==="twitter"){return (
            <p key={item.type} className="perfil_contacts">
              <a target={iosAgent?"":"_blank"} href={item.url}>
                <FontAwesomeIcon className="contact_icon" icon={faTwitter} />
                /{getLastUrlContent(item.url)}
              </a>
            </p>
          )}else{
          return (
            <p key={item.type} className="perfil_contacts">{item.type} <a target={iosAgent?"":"_blank"} rel="noopener noreferrer" href={item.url}>{item.url}</a></p>
          )}
          
        })}
        </div>
      )
    }
  };
  const getLastUrlContent = (url) => {
    const array = url.split("/")
    return array[array.length-1]?array[array.length-1]:array[array.length-2]

  };
  const showProductsButton = () => {
     if(mdi_id){ 
      return (
      <button type="button" onClick={() => setStatusPopup("enabled")} 
      className={"form__btn--secundary products "+joyceLayout}
      >
        {text.listaProdutos}
      </button>
  
     )}
  };
  const handlePerfilSubmit = async (event) => {
    event.preventDefault();
    try{
      const newData = {
        email,
        nome,
        sobrenome,
        nascimento,
        profissao: profissaoCodigo,
        sexo,
        pais: paisCodigo,
        cep,
        cidade,
        estado,
        bairro,
        endereco,
        numero,
        complemento,
        celularDdi,
        celular,
        telefoneDdi,
        telefone,
        mdi_id,
      };
      if (senha !== "") {
        newData["senha"] = senha;
      }
      const resp = await setNewDataProfile(newData, mobile);

      if(mobile) {
        setPopupBtn(false)
        showFinalMessage(text.atualizacaoSucesso)
      }else {
        if (resp.perfil_login) {
          setPopupBtn("logout")
          showFinalMessage(text.atualizacaoLogout)
        }else{
          showFinalMessage(text.atualizacaoSucesso)
        }
      }
    }catch(e){
      showFinalMessage(e.message)
      console.error(e)
    }

  };
  const logoutPerfil = () => {
    destroyToken();
    if(view)history.push("/"+mdi_id);
  };
  const showFinalMessage = (text) => {
    setPopupMessage(text);
    setStatusPopup("enable");
  };
  
  return (
    <div className={"perfil_page "+joyceLayout}>
       <PopupPerfil 
        logoutPerfil={logoutPerfil}
        popupBtn={popupBtn}
        popupMessage={popupMessage} 
        setPopupMessag={setPopupMessage} 
        iosAgent={iosAgent} 
        text={text} 
        status={statusPopup} 
        listaGrupos={listaGrupos} 
        listaGruposComprar={listaGruposComprar} 
        setStatusPopup={setStatusPopup}/>
     
      <form
        id="formPerfil"
        onSubmit={handlePerfilSubmit}
        className={"page__content "+joyceLayout}
      >
        {showHeader()}
        
    <div className={"perfil_inputContainer "+joyceLayout}>
        <input
          placeholder={text.email}
          id="inputEmail"
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className={"form__input "+joyceLayout}
        />

        <input
          ref={inputSenha}
          placeholder={text.novaSenha}
          id="inputSenha"
          type="password"
          onChange={(e) => setSenha(e.target.value)}
          value={senha}
          onBlur={(e) => confirmarSenha(e.target.value)}
          className={"form__input "+joyceLayout}
        />
        <input
          ref={inputConfirmaSenha}
          placeholder={text.confirmacaoSenha}
          id="inputConfirmacaoSenha"
          type="password"
          className={"form__input "+joyceLayout}
          onBlur={(e) => confirmarSenha(e.target.value)}
        />
        <input
          placeholder={text.nome}
          id="inputNome"
          type="text"
          onChange={(e) => setNome(e.target.value)}
          className={"form__input "+joyceLayout}
          value={nome}
        />
        <input
          placeholder={text.sobrenome}
          id="inputSobrenome"
          type="text"
          className={"form__input "+joyceLayout}
          onChange={(e) => setSobrenome(e.target.value)}
          value={sobrenome}
        />
        {tipoForm===1||tipoForm===3?<input
          ref={inputNascimento}
          placeholder={text.nascimento}
          id="inputNascimento"
          type="text"
          className={"form__input "+joyceLayout}
          onChange={(e) => handleNascimento(e.target.value)}
          onBlur={(e) => confirmarNascimento(e.target.value)}
          value={nascimento}
          maxLength="10"
        />:""}
        {tipoForm===1?showProfession():""}

        {tipoForm===1||tipoForm===3?<select
          id="inputSexo"
          onChange={(e) => setSexo(e.target.value)}
          className={"form__input "+joyceLayout}
          list="sexo"
          name="sexo"
        >
          {showSexo()}
        </select>:""}

        {tipoForm===1||tipoForm===3?showPais():""}

        {tipoForm===1?<input
          placeholder={text.cep}
          onChange={(e) => handleCep(e.target.value)}
          value={cep}
          onBlur={(e) => confirmarCep(e.target.value)}
          id="inpuCep"
          className={"form__input "+joyceLayout}
          name="cep"
          type="text"
        />:""}
        {tipoForm===1?<input
          placeholder={text.estado}
          onChange={(e) => setEstado(e.target.value)}
          value={estado}
          id="inputEstado"
          className={"form__input "+joyceLayout}
          name="estado"
          type="text"
        />:""}
        {tipoForm===1?<input
          placeholder={text.cidade}
          onChange={(e) => setCidade(e.target.value)}
          value={cidade}
          id="inputCidade"
          className={"form__input "+joyceLayout}
          name="cidade"
          type="text"
        />:""}
        {tipoForm===1?<input
          placeholder={text.endereco}
          onChange={(e) => setEndereco(e.target.value)}
          value={endereco}
          id="inputRua"
          className={"form__input "+joyceLayout}
          name="rua"
          type="text"
        />:""}
        {tipoForm===1?<input
          placeholder={text.numero}
          onChange={(e) => setNumero(e.target.value)}
          value={numero}
          id="inputNumero"
          className={"form__input "+joyceLayout}
          name="numero"
          type="text"
        />:""}
        {tipoForm===1?<input
          placeholder={text.bairro}
          onChange={(e) => setBairro(e.target.value)}
          value={bairro}
          id="inputBairro"
          className={"form__input "+joyceLayout}
          name="bairro"
          type="text"
        />:""}
        {tipoForm===1?<input
          placeholder={text.complemento}
          onChange={(e) => setComplemento(e.target.value)}
          value={complemento}
          id="inputComplemento"
          className={"form__input "+joyceLayout}
          name="complemento"
          type="text"
        />:""}

        {tipoForm===1||tipoForm===3?<div className="perfil__celular">
          <input
            placeholder={text.ddi}
            onChange={(e) => setCelularDdi(handleDdi(e.target.value))}
            maxLength="5"
            value={celularDdi}
            id="inputDdiCelular"
            name="dddcelular"
            className={"form__input "+joyceLayout}
          />
          <input
            placeholder={text.celular}
            onChange={(e) => handleCelular(e.target.value)}
            value={celular}
            minLength="11"
            id="inputCelular"
            name="celular"
            className={"form__input "+joyceLayout}
          />
        </div>:""}

        {tipoForm===1?<div className="perfil__celular">
          <input
            placeholder={text.ddi}
            onChange={(e) => setTelefoneDdi(handleDdi(e.target.value))}
            maxLength="5"
            value={telefoneDdi}
            id="inputDdiTelefone"
            name="celular"
            className={"form__input "+joyceLayout}
          />
          <input
            placeholder={text.telefone}
            onChange={(e) => handleTelefone(e.target.value)}
            minLength="11"
            value={telefone}
            id="inputTelefone"
            name="telefone"
            className={"form__input "+joyceLayout}
          />
        </div>:""}
        </div>
        {showProductsButton()}

        <div className={"popup__response "+joyceLayout}>
          {mobile?"":<button
            onClick={history.goBack}
            type="button"
            className={"form__btn--secundary "+joyceLayout}
            >
            {text.voltar}
          </button>}

          <button type="submit" 
            className={"form__btn--secundary "+joyceLayout}
            >
            {text.enviar}
          </button>
        </div>
        {showContacts()}
      </form>
      
    </div>
  );
}
