import React, { useState, useEffect, useRef,useContext } from "react";
import useNavigationSetup from '../../hooks/useNavigationSetup';
import usePopup from '../../hooks/usePopUp';

import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {audioDataContext} from '../../context';
import {contentDataContext} from '../../context';
import {layoutContext} from '../../context';
import {userContext} from '../../context';

import { useHistory, Route } from "react-router-dom";
import { getToken } from "../../utils";

import {getLayout, getCarrrosselData,getOutletData,getUserData,getprofissoes,getPaises,getValidation} from '../../api';

import Carrossel from "./Carrossel";
import ItemContato from "./ItemContato";
import Item from "./Item";

import Outlet from "./Outlet";

import Perfil from '../../components/perfil';
import NeoLayout from '../../components/neoLayout';
import VideoPlayer from '../../components/videoPlayer'
import AudioPlayer from "../../components/audioPlayer";

export default function NeoCarrossel(props) {
  const history = useHistory();
  const setNavi = useNavigationSetup()
  const mdi_id = props.match.params.mdi_id
  
  const [openingVideo, setOpeningVideo] = useState(true)
  const [isCarrusselScreen, setIsCarrusselScreen] = useState(false)
  const [isSearchScreen, setIsSearchScreen] = useState(false)
  const [carrosselData, setCarrosselData] = useState([])

  const [audioContext, setAudioContext] = useState(null)
  const [currentSongContext, setCurrentSongContext] = useState(0)
  const [outletContext, setOutletDataContext] = useState(null)
  const [itemContext, setItemContext] = useState(null)
  const [setPopUpMessage, setPopUpFunction] = usePopup(null);

  const [
    {layoutData,setLayoutData},
    {language},
    {text},
  ] = useContext(layoutContext)
  const [
    {_,setUserData},
    {__,setProfessions},
    {___,setCountries}
  ] = useContext(userContext)
  const playerContextData = [
    {audioContext,setAudioContext},
    {currentSongContext,setCurrentSongContext}
  ]
  const contextContextData = [
    {itemContext,setItemContext},
    {outletContext,setOutletDataContext},
  ]

  const openingRef = useRef()

  const start = async () => {
    const valid = await getValidation(getToken());
    if(!valid){
      history.push("/");
      if(props.match.params.view==="carrossel")history.push("/"+mdi_id);
    }
    else {
      getCarrrosselLayout()
      getCarrrossel()
      getUser()
    }
  }
  const getCarrrosselLayout = async () => {
    const data = await getLayout(mdi_id, language)
    getLangCountries(data)
    getLangProfissoes(data)
    setLayoutData(data)
  }
  const getUser = async () => {
    const data = await getUserData(null, mdi_id)
    setUserData(data)
  }
  const getCarrrossel = async () => {
    try{
      const apiCarrosselData = await getCarrrosselData(mdi_id,language)
      setCarrosselData(apiCarrosselData)
    }catch(e){
      setPopUpFunction(()=>history.goBack())
      setPopUpMessage(text.erroSolicitacao)
      console.error(e)
    }

  }

  const getLangProfissoes = async (data) => {
    const dataProfessions = await getprofissoes(language,data.slug)
    setProfessions(dataProfessions)
  }
  const getLangCountries = async (data) => {
    const dataCountries = await getPaises(language,data.slug)
    setCountries(dataCountries)
  }
  const accessItemCarrossel = async  (type,data) => {
    if(type){
      console.log(type)
      if(type==="elive"||type==="contato"||type==="conteudolivre") {
        goToItem(data,type)
        console.log(type)
        
      }else {
        console.log(type)
        goToOutlet(data,type)
      }
    }
  }
  const goToItem = (data,type) => {
    setItemContext(data)
    history.push(`${mdi_id}/${type}`);
  }
  const goToOutlet = async (data,type) => {
    try{
      const galeryData = await getOutletData(mdi_id,data,type)
        
      setOutletDataContext(galeryData)
      history.push(`${mdi_id}/${type}/${data.id}`);
    }catch(e){
      setPopUpFunction(()=>history.goBack())
      setPopUpMessage(text.erroSolicitacao)
      console.error(e.message)
    }
    
  }

  useEffect(() => {
    start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateSearch = () => {
      const setSearchWhen = (props.match.params.type==="video"
                          ||props.match.params.type==="outlet"
                          ||props.match.params.type==="documento")
                          &&!props.match.params.itemType
      if(setSearchWhen)setIsSearchScreen(true)
      else setIsSearchScreen(false)
    }
    const updateNavigationItens = () => {
      if(!props.match.params.type&&!isCarrusselScreen) {
        setIsCarrusselScreen(true)
      }else if(props.match.params.type&&isCarrusselScreen){
        setIsCarrusselScreen(false)
      }
      updateSearch()

    }
    updateNavigationItens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    const navigation={
      menu:true,
      carrossel: isCarrusselScreen,
      view: props.match.params.view,
      back:true,
      mdi_id: mdi_id,
      search: isSearchScreen,
      menuButton:true

    }
    setNavi(navigation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCarrusselScreen,isSearchScreen]);

  return (
      <div className="login__body layout__body">
        <NeoLayout layout={layoutData} />

        {layoutData.abertura_video&&isCarrusselScreen?<>

          <div onClick={()=>setOpeningVideo(true)} className="neo_openingThumb" >
            <img src={layoutData.abertura_thumb} alt="video de abertura"/>
            <FontAwesomeIcon className="neo_openingIcon" icon={faPlayCircle} />
          </div>
            {openingVideo? 
              <div ref={openingRef} className="neo_openingVideo">
                <button onClick={()=>setOpeningVideo(false)} className="neo_openingVideo_close">X</button>
                <VideoPlayer  
                  url={layoutData.abertura_video} 
                  opening={setOpeningVideo}
                />
              </div>
            :<></>}
          </>
          :<></>
        }

        <audioDataContext.Provider value={playerContextData}>
          <contentDataContext.Provider value={contextContextData}>
            <>
            <Route
                  path={`/:view/:mdi_id/profile/`}
                  exact
                  component={() => <Perfil 
                    isCarrusselScreen={isCarrusselScreen}
                    {...props}
                    />}
              />
            <Route
                  path={[`/:view/:mdi_id/conteudolivre/`,`/:view/:mdi_id/elive/`,`/:view/:mdi_id/:type/:key/:itemType`]}
                  
                  exact
                  component={() => <Item
                    {...props}
                    text={text}
                  />}
              />
              <Route
                  path={`/:view/:mdi_id/contato/`}
                  exact
                  component={() => <ItemContato 
                    {...props}
                    text={text}
                  />}
              />
              <Route
                  path={`/:view/:mdi_id/:galery/:key`}
                  
                  exact
                  component={() => <Outlet
                    {...props}
                    text={text}
                  />}
              />
               
              <Route
                path={[`/carrossel/:mdi_id`, `/neo/:mdi_id`]}
                exact
                component={() => (
                  <Carrossel  
                  carrosselData={carrosselData} 
                  accessItemCarrossel={accessItemCarrossel}
                  />
                )}
              />
            </>
            <AudioPlayer />
          </contentDataContext.Provider>
        </audioDataContext.Provider>
      </div >
  );
}
