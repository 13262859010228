import React, { useState, useEffect, useContext } from "react";

import { getValidation, getUserData, getLayout, getprofissoes, getPaises } from "../api";
import { extractTokenUrl, extractParamsUrl } from "../utils";

import NeoLayout from '../components/neoLayout';
import Perfil from "../components/perfil";

import {layoutContext} from '../context';
import {userContext} from '../context';


export default function PerfilMobile(props) {

  const [
    {layoutData,setLayoutData},
    {language},
    {text},
  ] = useContext(layoutContext)

  const [
    {_,setUserData},
    {__,setProfessions},
    {___,setCountries}
  ] = useContext(userContext)

  const token = extractTokenUrl(props.location.pathname)
  
  const {mdi_id, lang , logo} = extractParamsUrl(props.location?.search)
   
  const [valid, setValid] = useState(null)

  useEffect(() => {
    validadeToken(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const validadeToken = async (token) =>{
    try{
      await getValidation(token)
      if(mdi_id) await getContextLayoutData()
      else if (lang) await setPerfilData(lang)
      else await setPerfilData(language)
      await getContextUserData()
      setValid(true)
    }catch(e){
      console.error(e)
      setValid(false)
    }
  }
  const getContextLayoutData = async () => {
    try{
      const layout = await getLayout(mdi_id)
      if(lang){
        layout.idioma = lang
        setPerfilData(lang)
      }   
  
      setLayoutData(layout)
    }catch(e){
      throw Error(e)
    }finally{
      
    }
    

  }
  const getContextUserData = async () => {
    try{
      const user = await getUserData(token, mdi_id)
      setUserData(user)
    }catch(e){
      console.error(e)
    }
  }
  const setPerfilData = async (lang) => {
    try{
      setCountries(await getPaises(lang))
      setProfessions(await getprofissoes(lang))
    }catch(e){
      console.error(e)
    }
    
  }

  return (
    <div className={mdi_id?"mobile__body":""}>
      <NeoLayout layout={layoutData} />
      {valid?<Perfil {...props} logo={logo} mdi_idMobile={mdi_id} mobile={token} />:<></>}
      {valid===false?<>{text.semValidacao}</>:<></>}
    </div>
  );
}