import axios from "axios";
import { getToken,
  loading,
  formatArray,
  formatArrayForOutlet,
  isEnglish } from "../utils";
import sha1 from "sha1";


const baseUrl = "https://app.neoidea.com.br/sistema/index.php?r=outlet/services/";

const apiSimple = axios.create({
  baseURL: baseUrl,
});

export async function setLogin(email, senha, lang) {
  
  try{
    loading(true)
    const response = await apiSimple.post("autenticaFaExterno", null, {
      params: {
        metodo: "sha1",
        email: sha1(email),
        senha: sha1(senha),
        lang
      },
    });
    if(response.data.retorno) await localStorage.setItem("AuthorizationNeo", response.data.dados.token)
    else throw Error(response.data.descricao)

  }catch(e){
    throw Error(e.message)
  }finally{
    loading(false)
  }

}
export async function getLayout(mdi_id, lang) {
  try{
    loading(true)
    const response = await apiSimple.post("getLayoutExterno", null, {
      params: {
        mdi_id: mdi_id,
        lang: lang,
      },
    });
    
    if(response.data.retorno) return response.data.dados;
    else throw Error(response.data.descricao)
  }catch(e){
    throw Error(e.message)
  }finally{
    loading(false)
  }
}
export async function getValidation(sendToken) {
  try{
    loading(true)

    if (sendToken) {
      const config = {
        method: "post",
        url:
          `${baseUrl}verificaAutenticacaoExterno`,
        headers: {
          Authorization: "Bearer " + sendToken,
        },
      };

      const response = await axios(config);

      if(response.data.retorno) return true
      else throw Error("invalid Token")
    }else{
      const config = {
        method: "post",
        url:
          `${baseUrl}verificaAutenticacaoExterno`,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      };

      const response = await axios(config);
    
      if(response.data.retorno) return true
      else throw Error("invalid Token")
    }
  }catch(e){
    throw (e)
  }finally{
    loading(false)
  }
}
export async function getUserData(sendToken, mdi_id) {
  try{
    if (sendToken) {
      const config = {
        method: "post",
        url:
          `${baseUrl}obterPerfilExterno`,
        headers: {
          Authorization: "Bearer " + sendToken,
        },
        params: {
          mdi_id,
        },
      };
  
      const response = await axios(config);
  
      if (response.data.retorno) {
        return response.data.dados;
      }
      return "no data";
    } else {
      const config = {
        method: "post",
        url:
          `${baseUrl}obterPerfilExterno`,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        params: {
          mdi_id,
        },
      };

      const response = await axios(config);

      if(response.data.retorno) return response.data.dados;
      else throw Error("no data");

    }
  }catch(e){
    console.error(e)
    throw (e)
  }  
  finally{
    loading(false)
  }
  
}
export async function AddNeoCode(code) {
  try{
    loading(true)

    const config = {
      method: "post",
      url:
        `${baseUrl}vincularConviteExterno`,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        convite: code,
      },
    };

    const response = await axios(config);
    loading(false)

    if(response.data.retorno) return response.data;
    else throw Error(response.data.descricao);

  }catch(e){
    throw (e)
  }
}
export async function sendForm(body,dataForm,lang) {
  try{
    loading(true)

    const config = {
      method: dataForm.method,
      url: dataForm.action,
      params: dataForm.params,
      body,
      lang
    };
   
    const response = await axios(config);
    if(response.data.status==="OK") return true;
    else throw Error(response.data);

  }catch(e){
    throw Error(e)
  }finally{
    loading(false)
  }
}
export async function getCarrrosselData(mdi_id,lang) {
  try{
    loading(true)

    const config = {
      method: "post",
      url:
        `${baseUrl}getCarrosselExterno`,
      headers: {
        Authorization: `Bearer `+ getToken(),
      },
      params: {
        mdi_id,
        lang,
      },
    };
  
    const response = await axios(config);

    if(response.data.length) return response.data;
    else throw Error(response.data.descricao);
    
  }catch(e){
    throw Error(e)
  }finally{
    loading(false)
  }
 
}
export async function getOutletData(mdi_id,data,type) {
  try{
    loading(true)

    const getData = await getCarrrosselSeccionData(mdi_id, type, data.id)
    if(type==="outlet"){
      const setupDataOutlet = getData.map( async item =>{
      const data = await getCarrrosselItemData(mdi_id, item.tipo, item.id)
    
      return {...item, seccionContent:data}
      })
      return  formatArrayForOutlet(await Promise.all(setupDataOutlet))
    }else return formatArray(getData,data.titulo,type)
  }catch(e){
    throw Error(e)
  }finally{
    loading(false)
  }

}
export async function getEncriptedVideoData(mdi_id, id, type) {
  try{
    loading(true)

    const config = {
      method: "post",
      url:
        `${baseUrl}getDrmExterno`,
      headers: {
        Authorization: `Bearer `+ getToken(),
      },
      params: {
        mdi_id,
        tipo:type,
        id,
      },
    };

    const response = await axios(config);

    if(response.data.url) return response.data;
    else throw Error(response.data.descricao);

  }catch(e){
    throw Error(e)
  }finally{
    loading(false)
  }
}
export async function setNewDataProfile(data, sendToken) {
  try{
    loading(true)

    const config = {
      method: "post",
      url:
        `${baseUrl}gravarPerfilExterno`,
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      params: {
        lang: data.lang,
        celular_numero: data.celular,
        celular_ddi: data.celularDdi,
        cep: data.cep,
        cidade: data.cidade,
        complemento: data.complemento,
        bairro: data.bairro,
        email: data.email,
        endereco: data.endereco,
        estado: data.estado,
        nascimento: data.nascimento,
        profissao: data.profissao,
        nome: data.nome,
        numero: data.numero,
        pais: data.pais,
        sexo: data.sexo,
        sobrenome: data.sobrenome,
        telefone_numero: data.telefone,
        telefone_ddi: data.telefoneDdi,
        mdi_id: data.mdi_id,
      },
    };
  
    if (data.senha) {
      config.params.senha = data.senha;
    }
  
    if (sendToken) {
      config.headers = { Authorization: "Bearer " + sendToken };
    }
    const response = await axios(config);
    console.log(response.data)

    if(response.data.retorno) return response.data;
    else throw Error(response.data.descricao);

  }catch(e){
    throw Error(e)
  }finally{
    loading(false)
  }
  
}
export async function getPaises(lang,slug) {
  try{
    if(!slug){
      if (!isEnglish(lang)) {
        const response = await apiSimple.post("getPaisesExterno", null, {
          params: {
            slug: "mastercase",
            lang: lang,
          },
        });
        return response.data.dados.paises;
      }

      const response = await apiSimple.post("getPaisesExterno", null, {
        params: {
          slug: "marc",
          lang: lang,
        },
      });

      return response.data.dados.paises;
    }else{
      const response = await apiSimple.post("getPaisesExterno", null, {
        params: {
          slug: slug,
          lang: lang,
        },
      });

      return response.data.dados.paises;
    }
  }catch(e){
    console.error(e.message)
  }finally{
    loading(false)
  }
}
export async function getprofissoes(lang,slug) {
  try{
    if(!slug){
      if (!isEnglish(lang)) {
        const response = await apiSimple.post("getProfissoesExterno", null, {
          params: {
            slug: "mastercase",
            lang: lang,
          },
        });
        return response.data.dados.profissoes;
      }

      const response = await apiSimple.post("getProfissoesExterno", null, {
        params: {
          slug: "marc",
          lang: lang,
        },
      });

      return response.data.dados.profissoes;
    }else{
      const response = await apiSimple.post("getProfissoesExterno", null, {
        params: {
          slug: slug,
          lang: lang,
        },
      });

      return response.data.dados.profissoes;
    }
  }catch(e){
    console.error(e.message)
  }finally{
    loading(false)
  }

}
export async function getLostPass(mail,lang) {
  try{
    loading(true)
    const response = await apiSimple.post("recuperarSenhaFaExterno", null, {
      params: {
        email: mail,
        lang,
      },
    });
    if(response.data.retorno) return response.data;
    console.error(response.data.descricao)

  }catch(e){
    throw Error(e.message)
  }finally{
    loading(false)
  }
}
export async function getNeosList() {
  try{
    loading(true)

    const config = {
      method: "post",
      url:
        `${baseUrl}getNeosExterno`,
      headers: {
        Authorization: `Bearer `+ getToken(),
      },
    };

    const response = await axios(config);

    return response.data.dados.filter((el) => el.tipoAcesso !== 1);
  }catch(e){
    console.error(e.message)
  }finally{
    loading(false)
  }
}
export async function buscarEnd(pais, cep) {
  try{
    loading(true)

    const response = await apiSimple.post("getCepExterno", null, {
      params: {
        pais: pais,
        codigo_postal: cep,
      },
    });
    return response.data;
  }catch(e){
    console.error(e.message)
  }finally{
    loading(false)
  }
}
export async function destruirSessaoNeo() {
  const data = {
    mdi: localStorage.getItem("neo"),
    user: localStorage.getItem("id"),
  };

  try {
    const config = {
      method: "post",
      url: "https://session.neoidea.com.br/v1/session/del",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    const response = await axios(config);
    loading(false)

    return response.data;
  } catch (error) {
    console.log(error.response.data);
    return;
  }
}


async function getCarrrosselItemData(mdi_id,type, id) {

    const config = {
      method: "post",
      url:
        `${baseUrl}getCarrosselExterno`,
      headers: {
        Authorization: `Bearer `+ getToken(),
      },
      params: {
        mdi_id,
        tipo:type,
        id,
      },
    };

    const response = await axios(config);

    if(response.data.length) return response.data;
    else throw Error(response.data.descricao);



}
async function getCarrrosselSeccionData(mdi_id,type, id) {

    loading(true)

    const config = {
      method: "post",
      url:
        `${baseUrl}getCarrosselExterno`,
      headers: {
        Authorization: `Bearer `+ getToken(),
      },
      params: {
        mdi_id,
        tipo:type,
        id,
      },
    };

    const response = await axios(config);

    if(response.data.length) return response.data;
    else throw Error(response.data.descricao);

}