import React, {useEffect, useState, useRef, useContext} from 'react';

import {useDispatch} from "react-redux";
import {setSearch} from "../redux/actions";
import {layoutContext} from '../context';

import { faSearch, faPlusCircle, faChevronLeft, faBars, faUserCircle, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {destroyToken} from '../utils';
import {useHistory, useRouteMatch} from 'react-router-dom'

import Menu from './menu'

export default function Navegacao({setup,hideLogo}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const router = useRouteMatch();
    const [
        {_,setLayoutData},
        {__},
        {text},
      ] = useContext(layoutContext)

    const [menu, setMenu] = useState(false);
    const [steachStatus, setSteachStatus] = useState(false);

    const searchInputElement = useRef(null)

    const logout = (mdi_id) => {
        destroyToken();
        if(mdi_id) history.push(`/`+mdi_id);
        else {
            history.push(`/`);
            setLayoutData({})
        }
    }
    const goToProfile =  (mdi_id) => {
        history.push(`/carrossel/${mdi_id}/profile`);
    }
    const ativarMenu =  () => {
        if(menu){
            setMenu(false)
        }else {
            setMenu(true)
        }
    }
    const addNeo = () => {
        history.push(`/home/addneo`);
    }
    const showHideInput = () => {
        searchInputElement.current.focus()
        setSteachStatus(steachStatus=>{
            hideLogo(!steachStatus)
            return setSteachStatus(!steachStatus)
        })
        clearSearch()
    }
    const clearSearch = () => {
        dispatch(setSearch(""))
        searchInputElement.current.value = ""
    }
    useEffect(() => {
        if(history.action===("POP"||"PUSH")) {
            dispatch(setSearch(""))
            setSteachStatus(false)
        }
    }, [router])


        return (<>
                <div id="menu_container" className="Login__navi">
                    {setup.search? (<>
                        <div 
                        className={
                            steachStatus?
                            "search_container " 
                            :"search_container invisible" 
                            }>

                            <input 
                                ref={searchInputElement}
                                id="searchInput"
                                className="home__search form__input" 
                                onChange={e => dispatch(setSearch(e.target.value))}                                
                                >
                            </input>
                            <button onClick={clearSearch} className="search_clearButton">X</button>
                        </div>
                        <FontAwesomeIcon onClick={showHideInput} className="Login__icon" icon={faSearch} /></>
                    ):<></>}

                    {setup.back? (
                        <FontAwesomeIcon onClick={() => history.goBack()} className="Login__icon" icon={faChevronLeft} />
                    ):<></>}
                    
                    {setup.addNeo? (
                        <FontAwesomeIcon onClick={addNeo} className="Login__icon" icon={faPlusCircle} />
                    ):<></>}
                    {setup.view==="carrossel"? (
                        <>
                            <FontAwesomeIcon onClick={()=>goToProfile(setup.mdi_id)} className="Login__icon" icon={faUserCircle} />
                            <FontAwesomeIcon onClick={()=>logout(setup.mdi_id)} className="Login__icon" icon={faSignOutAlt} />
                        </>
                    ):(
                        setup.hideBars?<></>:<FontAwesomeIcon onClick={ativarMenu} className="Login__icon" icon={faBars} />
                    )}
                </div>

                {menu? (
                       <Menu text={text} close={ativarMenu} logout={logout}/>
                ):<></>}
                
           
            </>
        );

        
  
    
    
    
}
 