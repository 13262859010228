import React, { useState, useEffect } from 'react';
// import Carousel from 'react-spring-3d-carousel';
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ButtelsCarrossel from '../../components/bulletsCarrossel';

export default function Carrossel({
  carrosselData,
  accessItemCarrossel
}) {
  const [selectedItemIndex, setSelectedItemIndex] = useState(false)
  const NextArrow = ({ onClick }) => {
    return (
      <div className='nextArrow' onClick={onClick}>
        <BsChevronRight />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className='prevArrow' onClick={onClick}>
        <BsChevronLeft />
      </div>
    );
  };
  const templateImages = carrosselData.map((item, index) => {
    const selected = selectedItemIndex!==false ? selectedItemIndex : carrosselData.indexOf(carrosselData.find(item => item.destaque));
    return (
      <div
        className={index === selected ? "activeSlide" : "slide"}
        key={item.id}
      >
        <div
         className="slideWrapper"
         onClick={()=>{if(index === selected)accessItemCarrossel(item.tipo,item)}}
         >
          {<img src={item.imagem} />}
        </div>
      </div>
    );
  });
  const settings = {
    centerMode: true,
    infinite: false,
    dots: false,
    speed: 300,
    slidesToShow: 3,
    centerPadding: "0",
    initialSlide: carrosselData.indexOf(carrosselData.find(item => item.destaque)),
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />,
    beforeChange: (current, next) => setSelectedItemIndex(next),
    responsive: [
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    setSelectedItemIndex(carrosselData.indexOf(carrosselData.find(item => item.destaque)))
  }, [carrosselData])
  return(
    <div className="neo_container">
      <div id="neo_carrossel" className="neo_carrossel">
          <Slider {...settings}>{templateImages}
          {/* workaroundSliderInfiniteFalseBug */}
              <div className="slideWrapper"/>
              <div className="slideWrapper"/>
          </Slider>
          <ButtelsCarrossel setSelectedItemIndex={setSelectedItemIndex} itens={carrosselData} position={selectedItemIndex} />
      </div>
    </div>
  )

}
