import React,{useEffect} from 'react'
import Iframe from '../components/iframe';
import useNavigationSetup from '../hooks/useNavigationSetup';


export default function Register({match}) {
  const setNavi = useNavigationSetup()

  useEffect(() => {
    const navigation={
      back: true,
      menu: true,
      hideBars: true,
    }
    setNavi(navigation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    return(
      <div className="iframeFull" >
        <Iframe src={`https://register.neoidea.live/newregister/joyce`} height={"100%"} width={"100%"}/>  
      </div>
  )

}
