import React,{useEffect,useContext} from 'react'
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faYoutube, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import usePopup from '../../hooks/usePopUp';

import {contentDataContext} from '../../context';
import {sendForm} from '../../api';

export default function Contato({text,match}) {
  const mdi_id = match.params.mdi_id
  const view = match.params.view
  const history = useHistory();
  const [setPopUpMessage] = usePopup();

  const [{itemContext}] = useContext(contentDataContext)

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const iosAgent = userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i )?true:false;
  
  useEffect(() => {
      if(!itemContext||itemContext.length===0) history.replace(`/${view}/${mdi_id}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

  const setForm = (data) => {
    if(data){
    return(
      <form onSubmit={e=>submitForm(e,data.form)} className="content_form">
        {setImput(data.form.fields)}
        {showContacts(data)}

        <button className="form__btn--secundary" type="submit">{text.enviar}</button>
      </form>
    )}
  }
  const submitForm = async (e,data) => {
    e.preventDefault()
    try{
      const body = buildBody()
      await sendForm(body,data)
      setPopUpMessage(text.mensagemEnviada)
    }catch(e){
      setPopUpMessage(text.erroSolicitacao)
      throw Error(e.message)
    }
  }
  const setDescription = (data) => {
    return {__html: data};
  }
  const buildBody = () => {
    let data = {}
    const allInputs = Array.from(document.querySelectorAll(".neo_Input"))

    const bodyParams = allInputs.map(input => {
      return JSON.parse(`{"${input.name}":"${input.value}"}`)
    })
    bodyParams.forEach(item=> (data = {...data,...item}))

    return data
  }
  const setImput = (data) => {
    const inputs = data.map(input => {
      if(input.type==="text"){
        return <textarea key={input.name} rows="5" className="form__input" name={input.name} required={input.required} placeholder={input.name}></textarea>
      }else {
        return <input key={input.name} className="form__input" name={input.name} required={input.required} placeholder={input.name}></input>
      }
   });
   return inputs
  }
  const showContacts = (item) => {
    
    if(item.redes_sociais){
      const data = item.redes_sociais
      const contsctsTypes = Object.keys(data)
      
      const arrayContacts = contsctsTypes.map(item => {
        
        if(data[item]) return {type:item,url:data[item]}
        return undefined
      }).filter(item=>item!==undefined)

    return (
      <div className="perfil_container">
      <h3>{text.contatos}</h3>
      {arrayContacts.map(item=>{
        if(item.type==="telefone"){return (
          <p key={item.type} className="perfil_contacts">
            <a target={iosAgent?"":"_blank"} href={`tel:${item.url}`}>
              <FontAwesomeIcon className="contact_icon" icon={faPhone} />
              {item.url}
            </a>
          </p>
        )}else if(item.type==="email"){return (
          <p key={item.type} className="perfil_contacts">
            <a target={iosAgent?"":"_blank"} href={`mailto:${item.url}`}>
              <FontAwesomeIcon className="contact_icon" icon={faEnvelope} />
              {item.url}
            </a>
          </p>
        )}else if(item.type==="youtube"){return (
          <p key={item.type} className="perfil_contacts">
            <a target={iosAgent?"":"_blank"} href={item.url}>
              <FontAwesomeIcon className="contact_icon" icon={faYoutube} />
              /{getLastUrlContent(item.url)}
            </a>
          </p>
        )}else if(item.type==="facebook"){return (
          <p key={item.type} className="perfil_contacts">
            <a target={iosAgent?"":"_blank"}  href={item.url}>
              <FontAwesomeIcon className="contact_icon" icon={faFacebook} />
              /{getLastUrlContent(item.url)}
            </a>
          </p>
        )}else if(item.type==="instagram"){return (
          <p key={item.type} className="perfil_contacts">
            <a target={iosAgent?"":"_blank"} href={item.url}>
              <FontAwesomeIcon className="contact_icon" icon={faInstagram} />
              /{getLastUrlContent(item.url)}
            </a>
          </p>
        )}else if(item.type==="linkedin"){return (
          <p key={item.type} className="perfil_contacts">
            <a target={iosAgent?"":"_blank"} href={item.url}>
              <FontAwesomeIcon className="contact_icon" icon={faLinkedin} />
              /{getLastUrlContent(item.url)}
            </a>
          </p>
        )}else if(item.type==="twitter"){return (
          <p key={item.type} className="perfil_contacts">
            <a target={iosAgent?"":"_blank"} href={item.url}>
              <FontAwesomeIcon className="contact_icon" icon={faTwitter} />
              /{getLastUrlContent(item.url)}
            </a>
          </p>
        )}else{
        return (
          <p key={item.type} className="perfil_contacts">{item.type} <a target={iosAgent?"":"_blank"} rel="noopener noreferrer" href={item.url}>{item.url}</a></p>
        )}
      })}
      </div>
    )
  }
  };
  const getLastUrlContent = (url) => {
    const array = url.split("/")
    return array[array.length-1]?array[array.length-1]:array[array.length-2]

  };

  return(
    <div className="neo_content">
      <div className="content_contato" >
        <h3 className="contato_titulo">{itemContext?.titulo}</h3>
        <p className="contato_text" dangerouslySetInnerHTML={setDescription(itemContext?.descricao)} ></p>
          {setForm(itemContext)}
      </div>
    </div>
  ) 

}
