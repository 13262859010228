import React, { useEffect} from 'react'
import useNavigationSetup from '../../hooks/useNavigationSetup';

import Perfil from '../../components/perfil'

export default function Profile(props) {
    const setNavi = useNavigationSetup()

    useEffect(() => {
        console.log(props)
        const navigation={
            logo:true,
            menu:true,
            back: true,
          }
          setNavi(navigation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className="page__placement">
            
            <Perfil {...props} ></Perfil>
            
        </div>
    )
}
