import React, { useState, useEffect, useContext, useRef } from 'react';
import useNavigationSetup from '../hooks/useNavigationSetup';
import usePopup from '../hooks/usePopUp';

import {useHistory} from 'react-router-dom'
import {setLogin,getLayout,getValidation} from '../api';
import {getToken,isAuthenticated} from '../utils';

import NeoLayout from '../components/neoLayout';
import ForgotPassword from '../components/forgotPassword';

import {layoutContext} from '../context';

export default function Login({match}) {
    const mdi_id = 229
    const setNavi = useNavigationSetup()
    const history = useHistory();
    
    const [setPopUpMessage] = usePopup();
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [forgetPassStatus, setForgetPassStatus] = useState(false);

    const [{layoutData ,setLayoutData}, {language}, {text}] = useContext(layoutContext)

    const formRef = useRef(null)

    const handleSubmit = async event =>{
        event.preventDefault();
        try{
            await setLogin(email,senha,language)
            if(mdi_id) history.push(`/carrossel/${mdi_id}`)
            else history.push(`/home`)
        }catch(e){
            setPopUpMessage(e.message)
        }
    }
    const handleRegister = async event =>{
        event.preventDefault();
        if(layoutData.slug) history.push(`/register`)
        // else history.push(`/register/neo-idea-platform`)
    }
    const applyNeoLayout = async () => {
        try{
            const data = await getLayout(mdi_id)
            const navigation={
                logo:data.logo?data.logo:false,
                menu:false,
            }
            setLayoutData(data)
            setNavi(navigation)
        }catch(e){
            setPopUpMessage(text.mdi_idInvalido)
            formRef.current.remove()
        }
    }
    const applyDefaultLayout = async () => {
        const navigation={
            logo:true,
            menu:false,
        }
        setNavi(navigation)
        setLayoutData({})
    }
    const checkNeoLayout = async () => {
        if(mdi_id){
            applyNeoLayout()
        }else{
            applyDefaultLayout()
        }
    }  
    const checkRedirect = () => {
        if(isAuthenticated()) validateSession()
    }
  
    const validateSession = async () => {
        try{
            const isValid = await getValidation(getToken())
            if(!mdi_id && isValid) history.push("/home")
        }catch(e){
            console.error(e)
        }
    }


    useEffect(() => {
        checkNeoLayout()
        checkRedirect()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (  <>
        <NeoLayout layout={layoutData} />
        <ForgotPassword text={text} lang={language} forgetPassStatus={forgetPassStatus} setForgetPassStatus={setForgetPassStatus}/>
        <div className="page_placement-center">
            <form  
            ref={formRef}
            onSubmit={handleSubmit} 
            className="form__login">
                <input 
                    id="inputEmail"
                    required={true}
                    placeholder={text.email} 
                    type="email" 
                    className="form__input"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />

                <input 
                    placeholder={text.senha} 
                    required={true}
                    type="password" 
                    className="form__input"
                    value={senha}
                    onChange={e => setSenha(e.target.value)}
                />

                <button  type="submit" className="form__btn">{text.entrar}</button>
                <div className="form__txt">
                    <button className="text_link" onClick={()=>setForgetPassStatus(true)}>{text.esqueceuSenha}</button>
                    <div className="form__ou"><hr/><p>{text.ou}</p><hr/></div>
                </div>
                <button onClick={handleRegister} className="form__btn--secundary">{text.criarNovaConta}</button>

            </form>
        </div>
        </>
    );
}

 
