import React,{useEffect,useContext, useState} from 'react'
import { useHistory } from "react-router-dom";

import {contentDataContext} from '../../context';
import {useSelector} from "react-redux";

import ContentSeccion from '../../components/contentSeccion';
import ContentFeaturedImage from '../../components/contentFeaturedImage';


export default function ItemOutlet({text,match}) {
  const key = match.params.key
  const mdi_id = match.params.mdi_id

  const history = useHistory();
  const [{_,setItemContext},{outletContext}] = useContext(contentDataContext)

  const [outletContextToShow, setOutletContextToShow] = useState(outletContext)
  const {searchInput} = useSelector(state => state);

  useEffect(() => {
    if(outletContext===null) history.replace(`/neo/${mdi_id}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    
    if(!searchInput||searchInput==="") setOutletContextToShow(outletContext)
    else {
      const newData = outletContext.map(seccion => {
        const newContent = seccion.seccionContent.filter(content => content.titulo.toLowerCase().includes(searchInput.toLowerCase()))
        return {...seccion, seccionContent: newContent}
      })

      setOutletContextToShow(newData.filter(seccion=>seccion.seccionContent.length>0))
    }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  const acessItem = (item,type) =>{ 
    if (type == 'buy' && item.url_compra) {
      window.open(item.url_compra, '_blank');
    } else {
      setItemContext(item);
      history.push(`${key}/${type}`);
    }
  }

  const setFeaturedItem = (item) => {
    if(item){
       const featuredItem = item?.find(item=>item.destaque)?item?.find(item=>item.destaque):item[0]
       return featuredItem
    }
 }

  return(
    <div className="neo_content-outlet">
      <ContentFeaturedImage acessItem={acessItem} data ={setFeaturedItem(outletContext)}/>
      <div className="content_container">
        {outletContextToShow?.map((item) => {
            return (
              <ContentSeccion 
                acessItem={acessItem}
                key={item.id} 
                data={item} 
                text={text} 
              />
              )
          })
        }
      </div>
    </div>
  ) 

}
