import React from 'react';
import {useHistory} from 'react-router-dom'
import { faHome, faUserAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export default function Menu({close, logout, text}) {
    const history = useHistory();
    const openPerfil = () => {
        if(window.location.pathname!=="/home/perfil"){
            history.push(`/home/perfil`);
            close()
        }else {
            close()
        }
    }
    const openHome = () => {
        history.push(`/home`);
        close()
    }
    const openLogout = () => {
        logout()
    }

    return (
        <div className="header__menu--content">

            <div onClick={close} className="header__menu"> </div>

            <div className="menu__content"><div className="menu__titulo" ><p>{text.menu}</p><span onClick={close}>x</span></div>
                <ul  className="menu__lista">
                    <li onClick={openHome}>
                        <FontAwesomeIcon className="Login__icon--menu" icon={faHome} />
                            {text.home}
                    </li>
                    <li onClick={openPerfil}><FontAwesomeIcon className="Login__icon--menu" icon={faUserAlt} />
                    {text.perfil}
                    </li>
                    <hr></hr>
                    <li onClick={openLogout}>
                        <FontAwesomeIcon className="Login__icon--menu" icon={faSignOutAlt} />
                        {text.logout}
                    </li>
                </ul>
            
            </div>
        </div>
    );
}
 