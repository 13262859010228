import React from 'react'

export default function ButtelsCarrossel({itens, position, setSelectedItemIndex}) {
    
  const showBullets = (index,position) => {
    if(index===position){
      return <div key={index} className="bullets_item-checked layout__bullets" ></div>
    }else{
      return <div key={index} onClick={()=>setSelectedItemIndex(index)} className="bullets_item"></div>
    }
  }

      return (
        <div className="bullets_container ">
          {itens? itens.map((_, index)=>{
          return showBullets(index,position)
        }):""}
        </div>
      )
        
  

}
