import React, { useEffect, useState, useContext } from "react";
import useNavigationSetup from '../../hooks/useNavigationSetup';
import usePopup from '../../hooks/usePopUp';

import {layoutContext} from '../../context';
import {userContext} from '../../context';

import { getNeosList, getValidation,getUserData,getprofissoes,getPaises } from "../../api";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getToken } from "../../utils";

import AddNeo from "./AddNeo";
import NeoList from "./NeoList";
import Profile from "./Profile";


export default function Home(props) {
  const setNavi = useNavigationSetup()
  const [allDataCollected, setAllDataCollected] = useState(false)

  const [setPopUpMessage, setPopUpFunction] = usePopup(null);

  const [
    {_},
    {language},
    {text},
  ] = useContext(layoutContext)

  const [
    {userData,setUserData},
    {professions,setProfessions},
    {countries,setCountries}
  ] = useContext(userContext)

  const history = useHistory();

  useEffect(() => {
    isValid();
    const navigation={
      logo:true,
      menu:true,
      back: true,
      menuButton:true
    }
    setNavi(navigation)
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValid = async () => {
    try{
      await getValidation(getToken());
      await verifyData()
      setAllDataCollected(true)
    }catch(e){
      history.push("/");
      console.error(e)
    }

  };
  const verifyData = async () => {
    try{
      if(!userData.id) await getAllUserData()
      else await getAllNeoListData()
    }catch(e){
      setPopUpFunction(()=>logout)
      setPopUpMessage(text.semValidacao)
      console.error(e)
    }


    if(!professions.length) await getLangProfissoes()

    if(!countries.length) await getLangCountries()
  }
  const getAllUserData = async () => {
    try{
      const user = await getUserData()
      const lista = await getNeosList()
      setUserData({...user,listaNeos:lista})
    }catch(e){ throw Error(e)}
  }
  const getAllNeoListData = async () => {
    try{
      const lista = await getNeosList()
      setUserData({...userData,listaNeos:lista})
    }catch(e){ throw Error(e)}
  }
  const getLangProfissoes = async () => {
    if(language){
      const dataProfessions = await getprofissoes(language)
      setProfessions(dataProfessions)
    }else{
      const dataProfessions = await getprofissoes(navigator.language)
      setProfessions(dataProfessions)
    }

  }
  const getLangCountries = async () => {
    if(language){
      const dataCountries = await getPaises(language)
      setCountries(dataCountries)
    }else{
      const dataCountries = await getPaises(navigator.language)
      setCountries(dataCountries)
    }

    
  }
  const logout = async () => {
    localStorage.removeItem("AuthorizationNeo")
    history.push("/")
  }
  return (
    <>
    
      <div className="page_placement-top">
      {allDataCollected?
        <React.Fragment>
          <Route
            path={`/home/`}
            exact
            component={() => (
              <NeoList 
              userData={userData}
              text={text}
              />
            )}
          />
          <Route
            path={`/home/addneo`}
            exact
            component={() => <AddNeo
              text={text}
              />}
          />
          <Route
            path={`/home/perfil`}
            exact
            component={() => <Profile
              {...props}
              />}
          />
          
        </React.Fragment>:<></>}
      </div>
    </>
  );
}
