import React from 'react';


export default function PopupPerfil({
    popupMessage,
    setPopupMessag,
    iosAgent,
    text,
    status,
    listaGrupos,
    listaGruposComprar,
    setStatusPopup,
    logoutPerfil,
    popupBtn
}) {

    const closePopup = () => {
        setPopupMessag("")
        setStatusPopup("disable")
    }
    const showProductList = () => {
        if(listaGrupos?.length>0){
            return (<>
                <h3>{text.seusProdutos}</h3>
                <ul className="perfil_listaProdutos">
                {listaGrupos?.map(item => {
                    return <li key={item}>{item}</li>
                    })}
                </ul>
                </>
            )
        }else return <p>{text.semListaProdutos}</p>
    }
    const showSubscriptionsList = () => {
        if(listaGruposComprar?.length>0){
            return (<>
                <h3>{text.assineProdutos}</h3>
                <ul className="perfil_comprarAssinaturas">
                {listaGruposComprar?.map(item => {
                    return <li key={item.imagem}><a  href={item.url} target={iosAgent?'_self':'_blank'} ><img alt={item.nome} src={item.imagem}/></a></li>
                    })}
                </ul>
                </>
            )
        }
    }
   
    return (  
        <div id="popupConponentperfil" className={`popup__background ${status}`}>
            <div className="form__popup">
                <div className="popup__title">
                    {popupMessage?popupMessage:""}
                    {popupMessage?<></>:showSubscriptionsList()}
                    {popupMessage?<></>:showProductList()}
                    </div>                        
                {popupBtn?(
                <button onClick={popupBtn===true?closePopup:logoutPerfil} className="form__btn--secundary">
                    Ok
                </button>):<></>}
            </div>
        </div>
    );
}
 